import { gql } from '@apollo/client'
import { ChildDataProps, graphql } from '@apollo/client/react/hoc'
import uniqid from 'uniqid'
import { SearchFilter } from 'suf_storybook'

export type InputProps = {
  query: string
  filter: SearchFilter
  sort: string
  dateRange: string
  item: string
  offset: number
  limit: number
}

type SearchResponse = { search: any }

type Variables = {
  item: string
  term: string
  filter: object
  sort: string
  dateRange: string
  offset: number
  limit: number
}

type ChildProps = ChildDataProps<InputProps, SearchResponse, Variables>

export enum SearchItem {
  All = 'ALL',
  Research = 'RESEARCH',
  Entity = 'ENTITY',
  Issue = 'ISSUE',
  CusipIsin = 'CUSIPISIN',
  Page = 'PAGE'
}

export const searchOverlayLimit = 1000

export default graphql<InputProps, SearchResponse, Variables, ChildProps>(
  gql`
    query Search(
      $item: SearchItem
      $term: String!
      $filter: SearchFilterInput
      $sort: String
      $dateRange: String
      $offset: Int
      $limit: Int
    ) {
      search(
        item: $item
        term: $term
        filter: $filter
        sort: $sort
        dateRange: $dateRange
        offset: $offset
        limit: $limit
      ) {
        totalResearchHits
        totalRacsHits
        totalEntityHits
        totalIssueHits
        totalVideoHits
        totalEventHits
        totalWebinarHits
        totalAudioHits
        totalPageHits
        totalHits
        audio {
          image {
            poster
          }
          createdDate
          permalink
          title
        }
        event {
          title
          permalink
          eventType
          locationName
          startDate
          endDate
          timeZone
          image {
            poster
            thumbnail
          }
        }
        webinar {
          title
          permalink
          eventType
          locationName
          startDate
          endDate
          timeZone
          image {
            poster
            thumbnail
          }
        }
        research {
          docType
          marketing {
            contentAccessType {
              name
              slug
            }
            language {
              name
              slug
            }
            analysts {
              firstName
              lastName
              role
              sequenceNumber
              type
            }
            countries {
              name
              slug
            }
            sectors {
              name
              slug
            }
          }
          title
          permalink
          abstract
          reportType
          publishedDate
          ratingOutlookCode
          ratingOutlook
          sectorOutlookCode
          sectorOutlook
        }
        racs {
          docType
          marketing {
            contentAccessType {
              name
              slug
            }
            language {
              name
              slug
            }
            analysts {
              firstName
              lastName
              role
              sequenceNumber
              type
            }
            countries {
              name
              slug
            }
            sectors {
              name
              slug
            }
          }
          title
          permalink
          abstract
          reportType
          publishedDate
        }
        entity {
          marketing {
            analysts {
              firstName
              lastName
              role
              sequenceNumber
              type
            }
            countries {
              name
              slug
            }
            sectors {
              name
              slug
            }
          }
          name
          ultimateParent
          ratings {
            orangeDisplay
            ratingCode
            ratingAlertCode
            ratingActionDescription
            ratingAlertDescription
            ratingTypeDescription
            ratingEffectiveDate
            correctionFlag
            ratingChangeDate
          }
          permalink
        }
        issue {
          permalink
          issue
          issueName
          issuer
          entityName
          debtLevel
          deal
          className
          subClass
          transaction {
            description
            id
            securityList {
              typeDescription
            }
          }
          ratingDate
          maturityDate
          cusip
          isin
          originalAmount
          currency
          couponRate
          subgroupName
          ratableTypeDescription
          commercialPaperType
          marketing {
            analysts {
              firstName
              lastName
              type
              sequenceNumber
            }
            countries {
              name
              slug
            }
            sectors {
              name
              slug
            }
          }
          ratings {
            orangeDisplay
            ratableName
            ratingActionDescription
            ratingAlertCode
            ratingAlertDescription
            ratingCode
            ratingEffectiveDate
            ratingLocalActionDescription
            ratingLocalValue
            ratingTypeDescription
            ratingTypeId
            recoveryEstimate
            recoveryRatingValue
            solicitFlag
            sortOrder
            filterRatingType
            filterNationalRatingType
            filterInvestmentGradeType
            correctionFlag
            ratingChangeDate
          }
        }
        video {
          image {
            poster
          }
          createdDate
          permalink
          title
        }
        page {
          title
          slug
          image {
            poster
            thumbnail
          }
        }
        totalHits
      }
    }
  `,
  {
    options: ({ item, query, filter, sort, dateRange, offset, limit }) => ({
      key: uniqid(),
      fetchPolicy: 'no-cache',
      variables: {
        item,
        term: query,
        filter,
        sort,
        dateRange,
        offset,
        limit
      }
    })
  }
)
