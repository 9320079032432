/* TODO: Separate functions into a utils file as much as possible */

import { gql } from '@apollo/client'
import { Link, navigate } from 'gatsby'
import _ from 'lodash'
import { DateTime } from 'luxon'
import queryString from 'query-string'
import React, {
  Component,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect
} from 'react'
import { ChildDataProps, graphql } from '@apollo/client/react/hoc'
import {
  FilterItemProps,
  FormatDate,
  MoreSection,
  Pager,
  ResultItemRenderLinkProps,
  SearchFilter,
  SearchResultsSection,
  SearchResultTypes,
  SearchTools,
  SearchViewTypes,
  NoSearchResults,
  SearchBar
} from 'suf_storybook'
// import { PiwikPushSearch } from '../analytics/Piwik'

import { compose } from '../lib'
import { Context, Constants } from '../overlayContext'

import hasLoading from '../components/hocs/hasLoading'
import hasSearch, {
  searchOverlayLimit,
  SearchItem
} from '../components/hocs/hasSearch'
import hasRuntimeQuery from '../components/hocs/hasRuntimeQuery'
import withLocation from '../components/hocs/withLocation'

import Main from '../components/main'
import SEO from '../components/seo'

export enum SearchResultLabels {
  ENTITY = 'Entities',
  RESEARCH = 'Insights',
  RACS = 'Rating Actions',
  ISSUE = 'Securities and Obligations',
  VIDEO = 'Videos',
  AUDIO = 'Podcasts',
  RELATED_PAGES = 'Related Pages',
  EVENT = 'Events',
  WEBINAR = 'Webinars'
}

export enum SearchConstants {
  DEFAULT_PREVIEW_LIMIT = 6,
  DEFAULT_ENTITY_PREVIEW_LIMIT = 3,
  DEFAULT_PAGE_SEARCH_LIMIT = 4,
  DEFAULT_LIMIT = 24,
  PAGE_LIMIT = 10
}

type State = {
  isSearchMenuClosed: boolean
  loadedQuery?: string
  query: string
  searchText: boolean
}

type SearchFilterResponse = { getSFSearchFilters: FilterItemProps[] }

const hasSearchFilters = graphql<SearchFilterResponse>(
  gql`
    query SearchFilters {
      getSFSearchFilters {
        text
        value
        items {
          filterType
          label
          value
        }
      }
    }
  `
)

const getDateRangeValue = (value: string, from?: Date, to?: Date) => {
  let startDate
  let endDate = DateTime.local()
  let dateRange = ''
  if (value === 'lastYear') {
    startDate = endDate.minus({ years: 1 })
  } else if (value === 'lastMonth') {
    startDate = endDate.minus({ months: 1 })
  } else if (value === 'lastWeek') {
    startDate = endDate.minus({ weeks: 1 })
  } else if (value === 'customDate' && from && to) {
    startDate = DateTime.fromJSDate(from)
    endDate = DateTime.fromJSDate(to)
  }

  if (startDate) {
    dateRange = `${startDate.toFormat('LLddyyyy')}-${endDate.toFormat(
      'LLddyyyy'
    )}`
  }
  return dateRange
}

/**
 * Get the sort value to pass to Elasticsearch for a given human-readable string
 * @param value the string 'relevancy' or 'recency'
 * @returns the corresponding Elasticsearch value
 */
const getSortValue = (value: string) => {
  return value === 'recency' ? 'date|1' : ''
}

const getItems = (
  activeViewType: string,
  results: any,
  sectionType: SearchResultTypes
) => {
  let items
  let RenderLink: any

  switch (sectionType) {
    case SearchResultTypes.ENTITY:
      RenderLink = ({ name, permalink }: ResultItemRenderLinkProps) => (
        <Link to={`/entity/${permalink}`} aria-label={name}>
          {name}
        </Link>
      )

      if (activeViewType === SearchViewTypes.DATA) {
        items = results.map((item: any) => {
          return {
            ...item,
            renderLink: RenderLink
          }
        })
      } else {
        items = results.map((item: any) => {
          const ratingList = _.get(item, 'ratings')
          const ultimateParent = _.get(item, 'ultimateParent')
          const ratingsDisplay = _.map(ratingList, (rating) => {
            const ratingValue = rating
              ? `${rating.ratingCode} ${
                  rating.ratingActionDescription
                } ${FormatDate(rating.ratingEffectiveDate, 'dd MMM yyyy')} ${
                  rating.ratingTypeDescription
                }`
              : ''
            return ratingValue
          })

          const entityRatings = ratingList
          return {
            ...item,
            ultimateParent,
            ratingsDisplay,
            entityRatings,
            renderLink: RenderLink
          }
        })
      }

      break
    case SearchResultTypes.RESEARCH:
      RenderLink = ({ title, permalink }: ResultItemRenderLinkProps) => (
        <Link to={`/${permalink}`} aria-label={title}>
          {title}
        </Link>
      )
      items = results.map((item: any) => ({
        ...item,
        renderLink: RenderLink
      }))
      break
    case SearchResultTypes.RACS:
      RenderLink = ({ title, permalink }: ResultItemRenderLinkProps) => (
        <Link to={`/${permalink}`} aria-label={title}>
          {title}
        </Link>
      )
      items = results.map((item: any) => ({
        ...item,
        renderLink: RenderLink
      }))
      break
    case SearchResultTypes.ISSUE:
      RenderLink = ({ title, permalink }: ResultItemRenderLinkProps) => {
        return (
          <Link to={`/${permalink}`} aria-label={title}>
            {title}
          </Link>
        )
      }
      items = results.map((item: any) => {
        const ratingList = _.get(item, 'ratings')
        const ratingsDisplay = _.map(ratingList, (rating) => {
          const ratingValue = rating
            ? `${rating.ratingCode} ${
                rating.ratingActionDescription
              } ${FormatDate(rating.ratingEffectiveDate, 'dd MMM yyyy')} ${
                rating.ratingTypeDescription
              }`
            : ''
          return ratingValue
        })
        const entityRatings = ratingList
        return {
          ...item,
          permalink: `entity/${item.permalink}`,
          title: item.issueName,
          ratingsDisplay,
          entityRatings,
          renderLink: RenderLink
        }
      })
      break
    case SearchResultTypes.VIDEO:
    case SearchResultTypes.AUDIO:
      return results.map((item: any) => ({
        ...item,
        permalink: `${item.permalink}?autoplay=true`
      }))
    case SearchResultTypes.RELATED_PAGES:
      RenderLink = ({ title, permalink }: ResultItemRenderLinkProps) => (
        <Link to={`/${permalink}`} aria-label={title}>
          {title}
        </Link>
      )
      items = results.map((item: any) => ({
        ...item,
        permalink: item.slug,
        link: { link: item.slug },
        header: {
          title: item.title,
          permalink: item.slug,
          link: { link: item.slug }
        },
        image: {
          url: item.image.thumbnail
        },
        renderLink: RenderLink
      }))
      break
    case SearchResultTypes.EVENT:
    case SearchResultTypes.WEBINAR:
      return results.map((item: any) => ({
        ...item
      }))
    default:
      items = results
  }
  return items
}

const getLabel = (sectionType: SearchResultTypes) => {
  switch (sectionType) {
    case SearchResultTypes.ENTITY:
      return SearchResultLabels.ENTITY
    case SearchResultTypes.RESEARCH:
      return SearchResultLabels.RESEARCH
    case SearchResultTypes.RACS:
      return SearchResultLabels.RACS
    case SearchResultTypes.ISSUE:
      return SearchResultLabels.ISSUE
    case SearchResultTypes.VIDEO:
      return SearchResultLabels.VIDEO
    case SearchResultTypes.AUDIO:
      return SearchResultLabels.AUDIO
    case SearchResultTypes.RELATED_PAGES:
      return SearchResultLabels.RELATED_PAGES
    case SearchResultTypes.EVENT:
      return SearchResultLabels.EVENT
    case SearchResultTypes.WEBINAR:
      return SearchResultLabels.WEBINAR
    default:
      return ''
  }
}

const showViewControl = (sectionType: SearchResultTypes) => {
  const sectionsWithViewControls = [
    String(SearchResultTypes.ENTITY),
    String(SearchResultTypes.RACS),
    String(SearchResultTypes.ISSUE),
    String(SearchResultTypes.RESEARCH)
  ]
  // TODO: FRWS-9800 - hide grid-vs-data controls. Remove if unused after 2024-04-01.
  return false && sectionsWithViewControls.includes(sectionType)
}

const printableSectionTypes = [
  SearchResultTypes.ENTITY,
  SearchResultTypes.ISSUE
]

type SharedResultsProps = {
  activeIndex: number
  activeViewType: string
  expanded: SearchResultTypes
  handleBackClick?: (e: MouseEvent | KeyboardEvent) => void
  handleMoreClick?: (e: MouseEvent | KeyboardEvent) => void
  handlePagerClick?: () => void
  handleViewTypeClick?: (viewType: string, sectionType: string) => void
}

type ResultsListProps = SharedResultsProps & {
  results: any[]
  sectionNumber: string
  sectionType: SearchResultTypes
  totalHits: number
}

const ResultsList = ({
  activeIndex = 1,
  activeViewType = SearchViewTypes.GRID,
  expanded = SearchResultTypes.NONE,
  handleBackClick = () => {},
  handleViewTypeClick = () => {},
  handleMoreClick = () => {},
  handlePagerClick = () => {},
  results = [],
  sectionNumber = '',
  sectionType = SearchResultTypes.NONE,
  totalHits = 0
}: ResultsListProps) => {
  const label = getLabel(sectionType)
  const items = getItems(activeViewType, results, sectionType)
  const totalPages = Math.ceil(totalHits / SearchConstants.DEFAULT_LIMIT)

  const previewLimit =
    sectionType === SearchResultTypes.ENTITY
      ? SearchConstants.DEFAULT_ENTITY_PREVIEW_LIMIT
      : SearchConstants.DEFAULT_PREVIEW_LIMIT

  const pageSearchLimit =
    sectionType === SearchResultTypes.RELATED_PAGES
      ? SearchConstants.DEFAULT_PAGE_SEARCH_LIMIT
      : previewLimit

  const dataView =
    expanded === sectionType ? SearchConstants.DEFAULT_LIMIT : previewLimit

  const limit =
    expanded === sectionType && activeViewType ? dataView : pageSearchLimit

  const isViewControlVisible = showViewControl(sectionType)

  const showPrintButton =
    expanded === sectionType &&
    activeViewType === 'data' &&
    printableSectionTypes.includes(sectionType)

  return (
    <>
      {(expanded === sectionType || expanded === '') && (
        <>
          <section className="section section--spacing-above">
            <Context.Consumer>
              {({ toggleOverlay }) => {
                return (
                  <SearchResultsSection
                    activeViewType={activeViewType}
                    handleBackClick={handleBackClick}
                    handleViewTypeClick={handleViewTypeClick}
                    togglePrintOverlay={() =>
                      toggleOverlay(Constants.SEARCH_PRINT_OVERLAY)
                    }
                    items={items.slice(0, limit)}
                    sectionNumber={sectionNumber}
                    sectionTitle={label}
                    sectionTotalHits={totalHits}
                    sectionType={sectionType}
                    showBackLink={expanded === sectionType}
                    showPrintButton={showPrintButton}
                    showViewControls={isViewControlVisible}
                  />
                )
              }}
            </Context.Consumer>
          </section>
          {expanded === sectionType && (
            <Pager
              activeIndex={activeIndex}
              handlePagerClick={handlePagerClick}
              pageLimit={SearchConstants.PAGE_LIMIT}
              totalPages={totalPages}
            />
          )}
        </>
      )}
      {expanded === '' && items.length > limit && (
        <MoreSection
          id={sectionType}
          handleClick={handleMoreClick}
          header={`More ${label}`}
        />
      )}
    </>
  )
}

type ResultsProps = SharedResultsProps & {
  data: any
  handleNoResultsLinkClick: (e: MouseEvent, expanded: string) => void
  query: string
}
const Results = compose([hasRuntimeQuery, hasSearch, hasLoading])(
  ({
    activeIndex,
    activeViewType,
    data = {},
    expanded,
    handleBackClick,
    handleMoreClick,
    handlePagerClick,
    handleNoResultsLinkClick,
    handleViewTypeClick
  }: // query
  ResultsProps) => {
    useEffect(() => {
      window && window.scrollTo(0, 0)
    }, [])

    const { search } = data
    let sectionNumber = 0
    const sections: any[] = []
    // const searchResultCount = search ? search.totalHits : 0
    // const PIWIKParams = {
    //   term: query,
    //   resultCount: searchResultCount
    // }
    // PiwikPushSearch(PIWIKParams)
    Object.values(SearchResultTypes).forEach((value) => {
      if (search && search[value] && search[value].length) {
        sectionNumber += 1
        sections.push({
          activeIndex,
          activeViewType,
          expanded,
          handleBackClick,
          handleViewTypeClick,
          handleMoreClick,
          handlePagerClick,
          results: search[value],
          sectionNumber: `0${sectionNumber}`,
          sectionType: value,
          totalHits:
            search[`total${value.charAt(0).toUpperCase()}${value.slice(1)}Hits`]
        })
      }
    })
    const expandedSearchAndNoResults =
      expanded &&
      search[
        `total${expanded.charAt(0).toUpperCase()}${expanded.slice(1)}Hits`
      ] === 0

    return sections && sections.length && !expandedSearchAndNoResults ? (
      <div className="search-results">
        {sections.map((section) => (
          <ResultsList {...section} key={section.sectionNumber} />
        ))}
      </div>
    ) : (
      <NoSearchResults handleLinkClick={handleNoResultsLinkClick} />
    )
  }
)

export type LocationItemProps = {
  viewType: string
  expanded: string
  query: string
}

export type LocationProps = {
  search: string
}

export type SearchProps = {
  data: ChildDataProps<SearchFilterResponse>
  location: LocationProps
}

const cusipIsinRegEx = '^.*(?=.{6,})(?=.*[0-9])[a-zA-Z0-9]+$'
const getOffsetLimit = (activeIndex: number) => {
  return {
    offset: SearchConstants.DEFAULT_LIMIT * (activeIndex - 1),
    limit: SearchConstants.DEFAULT_LIMIT
  }
}

const defaultUrlParams = {
  dateRange: '',
  dateValue: '',
  expanded: SearchResultTypes.NONE,
  filter: { sustainableFitchFlag: ['true'] },
  isIdentifier: false,
  item: SearchItem.All,
  limit: SearchConstants.DEFAULT_LIMIT,
  offset: 0,
  page: 1,
  query: '',
  sort: 'relevancy',
  viewType: String(SearchViewTypes.GRID)
}

const initialState: State = {
  isSearchMenuClosed: true,
  loadedQuery: '',
  query: '',
  searchText: false
}
class Search extends Component<SearchProps, State> {
  constructor(props: any) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const { query } = this.parseQueryParams()

    this.setState({
      loadedQuery: query,
      query,
      searchText: true
    })
  }

  /**
   * Determine the new query string based on the provided parameters and the
   * current value, then navigate to the new URL.
   *
   * Parameters provided in the argument take precedence over current values,
   * and providing parameters with the default value will exclude that
   * parameter from the resulting URL.
   * @param updatedParams any parameters to change from their current values
   */
  navigateToQueryString = (updatedParams: Partial<typeof defaultUrlParams>) => {
    const { query } = this.state
    const currentUrlParams = {
      ...this.parseQueryParams(),
      query
    }

    // For each possible URL parameter, determine if we need to update it,
    // keep it the same, or ignore it because it has the default value.
    const result: string[] = []
    Object.keys(defaultUrlParams).forEach((key) => {
      switch (key) {
        case 'expanded':
        case 'isIdentifier':
        case 'page':
        case 'query':
        case 'sort':
        case 'viewType': {
          let targetValue
          if (updatedParams[key] !== undefined) {
            targetValue = updatedParams[key]!
          } else if (currentUrlParams[key] !== undefined) {
            targetValue = currentUrlParams[key]
          } else break

          if (targetValue !== defaultUrlParams[key]) {
            result.push(`${key}=${encodeURIComponent(targetValue)}`)
          }
          break
        }

        case 'dateValue': {
          // Set dateValue as normal, but if the user has selected custom dates,
          // also set dateRange with the correct value
          let targetDateValue
          let targetDateRange
          if (updatedParams.dateValue !== undefined) {
            targetDateValue = updatedParams.dateValue
            if (targetDateValue === 'customDate') {
              targetDateRange = updatedParams.dateRange
            }
          } else if (currentUrlParams.dateValue !== undefined) {
            targetDateValue = currentUrlParams.dateValue
            if (targetDateValue === 'customDate') {
              targetDateRange = currentUrlParams.dateRange
            }
          } else break

          if (targetDateValue !== defaultUrlParams.dateValue) {
            result.push(`dateValue=${targetDateValue}`)
          }
          if (
            targetDateRange &&
            targetDateRange !== defaultUrlParams.dateRange
          ) {
            result.push(`dateRange=${targetDateRange}`)
          }
          break
        }

        case 'filter': {
          // Same logic as normal, but we need to use _.isEqual and
          // createURLFilterString because the filters field is an object
          let targetValue
          if (updatedParams.filter !== undefined) {
            targetValue = updatedParams.filter
          } else if (currentUrlParams.filter !== undefined) {
            targetValue = currentUrlParams.filter
          }
          if (targetValue && !_.isEqual(targetValue, defaultUrlParams.filter)) {
            result.push(this.createURLFilterString(targetValue))
          }
          break
        }

        default:
          break
      }
    })

    navigate(`/search?${result.join('&')}`)
  }

  createURLFilterString = (filters: SearchFilter) => {
    const filterKeys = Object.keys(filters)
      .filter((k) => k !== 'sustainableFitchFlag')
      .filter((k) => filters[k])
    const queryString: string[] = []
    filterKeys.forEach((filterType) => {
      if (filters[filterType]) {
        filters[filterType].forEach((filterValue: string) => {
          queryString.push(
            `filter.${filterType}=${encodeURIComponent(filterValue)}`
          )
        })
      }
    })
    return queryString.join('&')
  }

  handleBackClick = (event: MouseEvent | KeyboardEvent) => {
    event.preventDefault()
    this.navigateToQueryString({
      expanded: SearchResultTypes.NONE,
      page: 1,
      viewType: SearchViewTypes.GRID
    })
  }

  handleChange = (event: FormEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value
    this.setState({
      query,
      searchText: false
    })
  }

  handleCusipIsinClick = (isIdentifier: boolean) => {
    this.navigateToQueryString({ isIdentifier })
  }

  handleDatesClick = (dateValue: string, from?: Date, to?: Date) => {
    const dateRange = getDateRangeValue(dateValue, from, to)
    this.navigateToQueryString({ dateRange, dateValue })
  }

  handleFilterChange = (filter: any) => {
    this.navigateToQueryString({ filter })
  }

  handleViewTypeClick = (
    updatedViewType: string,
    sectionType: SearchResultTypes
  ) => {
    const { viewType } = this.parseQueryParams()
    if (updatedViewType !== viewType) {
      this.navigateToQueryString({
        expanded: sectionType,
        viewType: updatedViewType
      })
    }
  }

  handleMoreClick = (event: MouseEvent) => {
    const target = event.currentTarget.id
    event.preventDefault()
    this.navigateToQueryString({ expanded: target as SearchResultTypes })
  }

  handleNoResultsLinkClick = (e: MouseEvent, expanded: SearchResultTypes) => {
    e.preventDefault()
    this.navigateToQueryString({ ...defaultUrlParams, expanded })
  }

  handlePagerClick = (page: number) => {
    this.navigateToQueryString({ page })
  }

  handleSortByClick = (sort: string) => {
    this.navigateToQueryString({ sort })
  }

  handleSubmit = (event: FormEvent) => {
    if (event) event.preventDefault()

    const { query } = this.state
    const isIdentifier = this.isCusipIsinMatch(query)

    this.navigateToQueryString({
      expanded: SearchResultTypes.NONE,
      isIdentifier,
      page: 1,
      query,
      viewType: SearchViewTypes.GRID
    })
  }

  isCusipIsinMatch = (query: string) => !!query.match(cusipIsinRegEx)

  parseQueryParams = () => {
    const { location } = this.props

    // Copy the defaults and make sure we're not overwriting the default filters
    let result = _.cloneDeep(defaultUrlParams)

    if (location.search) {
      const queryParams = queryString.parse(location.search)
      Object.keys(queryParams).forEach((param) => {
        switch (param) {
          case 'page': {
            const page = Number(queryParams.page)
            const { offset, limit } = getOffsetLimit(page)
            result = {
              ...result,
              page,
              limit,
              offset
            }
            break
          }
          case 'expanded':
            result.expanded = queryParams.expanded as SearchResultTypes
            break
          case 'isIdentifier':
            result.isIdentifier = queryParams.isIdentifier === 'true'
            break
          case 'viewType':
            result.viewType = String(queryParams.viewType)
            break
          case 'query':
            if (queryParams.query !== null) {
              result.query = String(queryParams.query)
              if (!result.isIdentifier)
                result.isIdentifier = this.isCusipIsinMatch(result.query)
              result.item = result.isIdentifier
                ? SearchItem.CusipIsin
                : SearchItem.All
            }
            break
          case 'dateRange':
            result.dateRange = String(queryParams.dateRange)
            break
          case 'dateValue':
            {
              result.dateValue = String(queryParams.dateValue)
              const dateRange = String(queryParams.dateRange)
              let fromDate
              let toDate
              if (dateRange) {
                const hyphenPosition = dateRange.indexOf('-')
                fromDate = DateTime.fromFormat(
                  dateRange.substring(0, hyphenPosition),
                  'LLddyyyy'
                ).toJSDate()
                toDate = DateTime.fromFormat(
                  dateRange.substring(hyphenPosition + 1, dateRange.length),
                  'LLddyyyy'
                ).toJSDate()
              }
              result.dateRange = getDateRangeValue(
                result.dateValue,
                fromDate,
                toDate
              )
            }
            break
          case 'sort':
            result.sort = String(queryParams.sort)
            break
          default:
            break
        }
        if (param.includes('filter')) {
          const splitFilter = param.split('.')
          if (splitFilter) {
            if (typeof queryParams[param] === 'string') {
              result.filter[splitFilter[1]] = [queryParams[param]]
            } else {
              result.filter[splitFilter[1]] = queryParams[param]
            }
          }
        }
      })
    }

    return result
  }

  render() {
    const { isSearchMenuClosed, loadedQuery, query, searchText } = this.state

    const {
      page,
      viewType,
      dateRange,
      dateValue,
      expanded,
      filter,
      isIdentifier,
      item,
      limit,
      offset,
      sort
    } = this.parseQueryParams()

    const sortValue = getSortValue(sort)

    const searchParams = {
      query,
      filter,
      sort: sortValue,
      dateRange,
      item: expanded.toUpperCase(),
      offset: 0,
      limit: searchOverlayLimit
    }

    const {
      data: { getSFSearchFilters: searchFilters }
    } = this.props

    return (
      <Main
        headerInverted={false}
        isSearchButtonVisible={false}
        searchParams={searchParams}
      >
        <SEO
          title="Search"
          type="SearchResult"
          keywords={[`Fitch Ratings`, `Search`, `Search Results`]}
        />
        <article className="article">
          <div className="wrapper--1">
            <div className="content">
              <SearchBar
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                query={query}
              />
              <SearchTools
                filters={searchFilters}
                handleDatesClick={this.handleDatesClick}
                handleCusipIsinClick={this.handleCusipIsinClick}
                handleFilterChange={this.handleFilterChange}
                handleSortByClick={this.handleSortByClick}
                isIdentifier={isIdentifier}
                isSearchMenuClosed={isSearchMenuClosed}
                selectedFilters={filter}
                selectedDates={dateValue}
                selectedDateRange={dateRange}
                selectedSort={sort}
              />
            </div>
            {searchText ? (
              <Results
                activeIndex={page}
                activeViewType={viewType}
                dateRange={dateRange}
                expanded={expanded}
                filter={filter}
                handleMoreClick={this.handleMoreClick}
                handleBackClick={this.handleBackClick}
                handleNoResultsLinkClick={this.handleNoResultsLinkClick}
                handlePagerClick={this.handlePagerClick}
                handleViewTypeClick={this.handleViewTypeClick}
                item={item}
                limit={limit}
                offset={offset}
                query={loadedQuery}
                sort={sortValue}
              />
            ) : null}
          </div>
        </article>
      </Main>
    )
  }
}

export default compose([
  hasRuntimeQuery,
  hasSearchFilters,
  hasLoading,
  withLocation
])((props: any) => <Search {...props} key={props.location.search} />)
