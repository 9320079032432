/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'

type State = {
  actionBarUserState: boolean
  activeOverlay: string
  activeMenu?: any
  isActionBarOpen: boolean
  isPopupOpen: boolean
  marketoFormId: string
  closeOverlay: () => void
  setMarketoFormId: (id: string) => void
  toggleOverlay: (activeOverlay: string, item?: any) => void
  toggleActionBar: () => void
  openActionBar: () => void
  closeActionBar: () => void
  closePopup: () => void
  handleEscKeyEvent: (event: any) => void
}

export enum Constants {
  AUSTRALIA_OVERLAY = 'australiaOverlay',
  MARKETO_OVERLAY = 'marketoOverlay',
  NONE = 'none',
  PERSONALIZATION_OVERLAY = 'personalizationOverlay',
  RATING_DETAILS_OVERLAY = 'ratingDetailsOverlay',
  SEARCH_OVERLAY = 'searchOverlay',
  SEARCH_PRINT_OVERLAY = 'searchPrintOverlay',
  SUB_NAVIGATION_OVERLAY = 'subNavigationOverlay',
  TRAFFIC_MONITOR_BG_COOLOR = '#e5e5e5'
}

export class ContextProvider extends Component<{}, State> {
  constructor(props: any) {
    super(props)
    const autoClosedActionBar =
      typeof window !== 'undefined' && window.outerWidth <= 1024
    this.closeOverlay = this.closeOverlay.bind(this)
    this.setMarketoFormId = this.setMarketoFormId.bind(this)
    this.toggleActionBar = this.toggleActionBar.bind(this)
    this.toggleOverlay = this.toggleOverlay.bind(this)
    this.openActionBar = this.openActionBar.bind(this)
    this.closeActionBar = this.closeActionBar.bind(this)
    this.closePopup = this.closePopup.bind(this)
    this.handleEscKeyEvent = this.handleEscKeyEvent.bind(this)
    this.state = {
      actionBarUserState: !autoClosedActionBar,
      activeOverlay: Constants.NONE,
      activeMenu: null,
      closeOverlay: this.closeOverlay,
      isActionBarOpen: !autoClosedActionBar,
      isPopupOpen: true,
      marketoFormId: '',
      setMarketoFormId: this.setMarketoFormId,
      toggleActionBar: this.toggleActionBar,
      toggleOverlay: this.toggleOverlay,
      openActionBar: this.openActionBar,
      closeActionBar: this.closeActionBar,
      closePopup: this.closePopup,
      handleEscKeyEvent: this.handleEscKeyEvent
    }
  }

  componentDidMount() {
    window.addEventListener('popstate', this.closeOverlay)
    window.addEventListener('keyup', this.handleEscKeyEvent)
    this.isMobileView()
  }

  isMobileView() {
    if (isMobile) {
      this.closeActionBar()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.closeOverlay)
    window.removeEventListener('keyup', this.handleEscKeyEvent)
  }

  setMarketoFormId(id: string) {
    this.setState({ marketoFormId: id })
  }

  // RWR-3234
  handleEscKeyEvent(event: any) {
    if (event.keyCode === 27) {
      const { actionBarUserState } = this.state
      this.setState({
        activeMenu: null,
        activeOverlay: Constants.NONE,
        isActionBarOpen: actionBarUserState
      })
    }
  }

  closeOverlay() {
    const { actionBarUserState } = this.state
    this.setState({
      activeMenu: null,
      activeOverlay: Constants.NONE,
      isActionBarOpen: actionBarUserState
    })
  }

  toggleActionBar() {
    const { isActionBarOpen } = this.state
    this.setState({
      actionBarUserState: !isActionBarOpen,
      isActionBarOpen: !isActionBarOpen
    })
  }

  openActionBar() {
    this.setState({ isActionBarOpen: true })
  }

  closeActionBar() {
    this.setState({ isActionBarOpen: false })
  }

  closePopup() {
    this.setState({ isPopupOpen: false })
  }

  toggleOverlay(newActiveOverlay: string, item?: any) {
    let { activeMenu, activeOverlay } = this.state
    const { actionBarUserState } = this.state
    let { isActionBarOpen } = this.state

    if (item && !activeMenu) {
      activeOverlay = newActiveOverlay
      activeMenu = item
      isActionBarOpen = false
    } else if (item && activeMenu && item.label === activeMenu.label) {
      activeOverlay = Constants.NONE
      activeMenu = null
      isActionBarOpen = actionBarUserState
    } else if (item && activeMenu && item.label !== activeMenu.label) {
      activeOverlay = newActiveOverlay
      activeMenu = item
      isActionBarOpen = false
    } else if (!item && newActiveOverlay !== activeOverlay) {
      activeOverlay = newActiveOverlay
      activeMenu = null
      isActionBarOpen = false
    } else if (!item && newActiveOverlay === activeOverlay) {
      activeOverlay = Constants.NONE
      activeMenu = null
      isActionBarOpen = actionBarUserState
    }

    this.setState({ activeMenu, activeOverlay, isActionBarOpen })
  }

  render() {
    const { children } = this.props
    return <Context.Provider value={this.state}>{children}</Context.Provider>
  }
}

export const Context = React.createContext({} as State)
