import React, { ComponentType } from 'react'
import { Location } from '@reach/router'

const parseLocationProps = (
  props: object = {},
  location: object,
  navigate: object
): any => ({
  ...props,
  location,
  navigate
})

export default (Component: ComponentType) =>
  (props: object = {}) =>
    (
      <Location>
        {({ location, navigate }) => (
          <Component {...parseLocationProps(props, location, navigate)} />
        )}
      </Location>
    )
